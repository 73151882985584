import React, { useState } from "react"
import {Button} from "./button"

const Cookies = () => {
    let localStorageName;
    let isAccepted;
    let setIsAccepted;
    const [isCookieAccepted, setIsCookieAccepted] = useState(false);
    const getLocalStorage = typeof window !== 'undefined' && window.localStorage;

    if (getLocalStorage) {
        localStorageName = 'ca-website-cookies-accept';
        isAccepted = getLocalStorage.getItem(localStorageName);
        setIsAccepted = () => {
            getLocalStorage.setItem(localStorageName, 'true');
            setIsCookieAccepted(true)
        }
    }

    return (
        <>{!(isAccepted === 'true' || isCookieAccepted)
            ? <aside className="cookies">
                <p className="body-text">CareAdvisors uses cookies to ensure you get the best experience on our website.</p>
                <Button
                    buttonText="I Accept"
                    action={setIsAccepted}
                    actionArg={true}
                />
            </aside>
            : null
        }
        </>
    )
}

export default Cookies