import { Link } from "gatsby"
import React from "react"

import infographicFile from "../pages/static/CA_CASS_Infographic_V3.4.pdf"

const CmsButton = ({ linkUrl, buttonText, action, actionArg, newTab }) => {
  return (
      <Link to={linkUrl} target={newTab ? '_blank' : '_self'}>
        <button
          className="btn"
          onClick={action && actionArg ? () => action(actionArg) : null}
        >
          {buttonText}
          <svg
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.63917 10C1.09917 9.42417 0.559167 8.84792 0.00375 8.25542C1.04417 7.21584 2.14625 6.11459 3.26167 5C2.14625 3.88542 1.04375 2.78417 0 1.74125C0.556667 1.14958 1.09792 0.575 1.63917 0C1.65292 0 1.66708 0 1.68083 0C1.7275 0.0579167 1.77 0.120417 1.82208 0.172917C3.35417 1.7225 4.8875 3.27084 6.42042 4.82C6.47375 4.87375 6.52542 4.92917 6.5925 4.99959C6.53667 5.05834 6.48667 5.11292 6.435 5.16542C4.90208 6.71417 3.36875 8.26292 1.83667 9.81251C1.77958 9.87001 1.7325 9.93751 1.68083 10.0004C1.66667 10 1.65292 10 1.63917 10Z"
              fill="#000"
            />
          </svg>
        </button>
      </Link>
  )
}

const Button = ({ buttonText, action, actionArg }) => {
  let route = null
  let isExternal = false

  switch (buttonText) {
    case "Request Free Demo":
      route = "/request";
      break;
    case "Join Us Now":
      route = "https://www.builtinchicago.org/company/careadvisors/jobs";
      isExternal = true;
      break;
    // case "Register Here":
    //   route =
    //     "https://www.bigmarker.com/careadvisors/CareAdvisors-Public-Health-Virtual-Town-Hall";
    //   isExternal = true;
    //   break;
    case "Watch Video":
      route = "/video-form";
      break;
    case "Download Infographic":
      route = "/infographic-form";
      break;
    case "Download Infographic File":
      route = infographicFile;
      isExternal = true;
      break;
    default:
      isExternal = true;
      break;
  }

  return (
    <>
      {!isExternal
        ? (
          <Link to={route ? route : null}>
            <button
              className="btn"
              onClick={action && actionArg ? () => action(actionArg) : null}
            >
              {buttonText}
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.63917 10C1.09917 9.42417 0.559167 8.84792 0.00375 8.25542C1.04417 7.21584 2.14625 6.11459 3.26167 5C2.14625 3.88542 1.04375 2.78417 0 1.74125C0.556667 1.14958 1.09792 0.575 1.63917 0C1.65292 0 1.66708 0 1.68083 0C1.7275 0.0579167 1.77 0.120417 1.82208 0.172917C3.35417 1.7225 4.8875 3.27084 6.42042 4.82C6.47375 4.87375 6.52542 4.92917 6.5925 4.99959C6.53667 5.05834 6.48667 5.11292 6.435 5.16542C4.90208 6.71417 3.36875 8.26292 1.83667 9.81251C1.77958 9.87001 1.7325 9.93751 1.68083 10.0004C1.66667 10 1.65292 10 1.63917 10Z"
                  fill="#000"
                />
              </svg>
            </button>
          </Link>
        )
        : (
          <a href={route}>
            <button
              className="btn"
              onClick={action && actionArg ? () => action(actionArg) : null}
            >
              {buttonText}
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.63917 10C1.09917 9.42417 0.559167 8.84792 0.00375 8.25542C1.04417 7.21584 2.14625 6.11459 3.26167 5C2.14625 3.88542 1.04375 2.78417 0 1.74125C0.556667 1.14958 1.09792 0.575 1.63917 0C1.65292 0 1.66708 0 1.68083 0C1.7275 0.0579167 1.77 0.120417 1.82208 0.172917C3.35417 1.7225 4.8875 3.27084 6.42042 4.82C6.47375 4.87375 6.52542 4.92917 6.5925 4.99959C6.53667 5.05834 6.48667 5.11292 6.435 5.16542C4.90208 6.71417 3.36875 8.26292 1.83667 9.81251C1.77958 9.87001 1.7325 9.93751 1.68083 10.0004C1.66667 10 1.65292 10 1.63917 10Z"
                  fill="#000"
                />
              </svg>
            </button>
          </a>
        )}
    </>)
}

export {Button, CmsButton}
