import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"

const GetBannerContent = (props) => {
    const bannerContent = useStaticQuery(graphql`
    query {
        allWp {
            nodes {
              siteOptions {
                wpSiteOptions {
                  notificationBanner {
                    bannerLinkUrl
                    bannerText
                    displayNotificationBanner
                  }
                }
              }
            }
        }
    }`)

    return bannerContent.allWp.nodes[0].siteOptions.wpSiteOptions.notificationBanner;
}

const CTA = ({ handleCloseCTA, isCTAVisible }) => (
    <>
        { GetBannerContent().displayNotificationBanner && isCTAVisible &&
            <aside className="cta">
                <Link to={GetBannerContent().bannerLinkUrl}>
                    <p className="body-text">{GetBannerContent().bannerText}</p>
                </Link>
                <div
                    onClick={() => handleCloseCTA()}
                    className="close">
                    Close
                </div>
                <div className="close-mobile" onClick={() => handleCloseCTA()}>
                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.0415 26L26.0415 1" stroke="white" />
                        <path d="M1.04175 0.999967L26.0409 26" stroke="white" />
                    </svg>
                </div>
            </aside>
        }
    </>
);

export default CTA;
