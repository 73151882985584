import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import HamburgerMenu from "react-hamburger-menu"
import headerLogo from "../images/CA-logo-header.svg"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import {Button} from "./button"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <header>
        <div>
        <Link to="/">
          <img src={headerLogo} alt="CareAdvisors logo" />
        </Link>
        <div className="menu-items view-desktop">
          <nav>
            <ul>
              <li>
                <Link to="/#solutions">Solutions</Link>
              </li>
              <li>
                <Link to="/events">Events</Link>
              </li>
              <li className="has-children">
                <Link to="#">Resources <FontAwesomeIcon icon={faCaretDown}/></Link>
                <ul className="submenu">
                  <li><Link to="/news">News</Link></li>
                  <li><Link to="/insights">Insights</Link></li>
                </ul>
              </li>
              <li>
                <Link to="/#contact">Contact Us</Link>
              </li>
            </ul>
          </nav>
          <Button buttonText="Request Free Demo" />
        </div>
        <div className="view-mobile">
          <HamburgerMenu
            className="hamburger-icon"
            isOpen={isOpen}
            menuClicked={() => setIsOpen(!isOpen)}
            color="#2E2E2E"
          />
          {isOpen
          ? <div className="mobile-menu">
              <nav>
                <ul>
                  <li>
                    <Link to="/" className="paragraph-text" onClick={() => setIsOpen(false)}>Homepage</Link>
                  </li>
                  <li>
                    <Link to="/#solutions" className="paragraph-text" onClick={() => setIsOpen(false)}>Solutions</Link>
                  </li>
                  <li><Link to="/events" className="paragraph-text" onClick={() => setIsOpen(false)}>Events</Link></li>
                  <li><Link to="/news" className="paragraph-text" onClick={() => setIsOpen(false)}>News</Link></li>
                  <li><Link to="/insights" className="paragraph-text" onClick={() => setIsOpen(false)}>Insights</Link></li>
                  <li>
                    <Link to="/#team" className="paragraph-text" onClick={() => setIsOpen(false)}>Team</Link>
                  </li>
                  <li>
                    <Link to="/#contact" className="paragraph-text" onClick={() => setIsOpen(false)}>Contact Us</Link>
                  </li>
                  <Button buttonText="Request Free Demo" />
                </ul>
              </nav>
            </div>
          : null
        }
        </div>
      </div>
    </header>
  )
}

export default Header
