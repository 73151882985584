import HubspotForm from "react-hubspot-form"
import { Link } from "gatsby"
import React from "react"
import footerLogo from "../images/CAlogo-footer.svg"
import linkedinLogo from "../images/icon-linkedin.svg"
import builtinLogo from "../images/icon-builtin.svg"

import styles from './footer.module.scss';

import { Button } from "./button";

const Footer = () => (
  <footer className={`${styles.footer} footer`}>
    <div className="container">

      <div className={`${styles.footerRow} ${styles.footerRowLogo}`}>

        <div className={styles.mobilePaddingBottom}>
          <img src={footerLogo} alt="CareAdvisors logo" id="footer-item1" />
        </div>

        <div className={styles.mobilePaddingBottom}>
          <p className={styles.paragraphText}>
            We lead interoperability in healthcare by connecting hospitals, social
            workers, and patients with innovative technology solutions.
          </p>
          <p className={styles.captionText}>
            515 N. State St. Suite 1025 | Chicago, IL, 60654
          </p>
        </div>

      </div>

      <div className={styles.footerRow}>

        <div className={`${styles.paddingBottom} ${styles.socialIconsContainer}`}>
          <nav className={styles.socialIcons}>
            <a href="https://www.linkedin.com/company/careadvisors/">
              <img src={linkedinLogo} alt="CareAdvisors LinkedIn" />
            </a>
            <a href="https://www.builtinchicago.org/company/careadvisors/jobs">
              <img src={builtinLogo} alt="CareAdvisors Built In Chicago" />
            </a>
          </nav>
        </div>

        <div className={`${styles.menu} ${styles.paddingBottom}`}>
          <nav className={styles.menuItems}>
            <ul>
              <li className="body-text">
                <Link to="/#solutions">Solutions</Link>
              </li>
              <li className="body-text">
                <Link to="/#team">Team</Link>
              </li>
              <li className="body-text">
                <Link to="/#contact">Contact Us</Link>
              </li>
            </ul>
          </nav>
          <Button buttonText="Request Free Demo" />
        </div>

        <div className={`${styles.mobilePaddingBottom} ${styles.legalLinksContainer}`}>
          <nav>
            <ul className={styles.legalInfo}>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms">Terms of Use</Link>
              </li>
            </ul>
          </nav>
          <p>{`© Copyright ${new Date().getFullYear()} CareAdvisors`}</p>
        </div>

        <div className={`${styles.subscribeContainer} ${styles.mobilePaddingBottom}`}>
          <p>Subscribe to Healthy Together</p>
          <HubspotForm
            portalId="4891903"
            formId="1c2e65b3-dd08-4808-8c9f-20f61f9db861"
          />
          <p>
            By entering your email address you are providing us with consent to send
            you our newsletter and marketing emails.
        </p>
        </div>

      </div>


    </div>
  </footer>
)

export default Footer
