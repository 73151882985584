/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import CTA from "./cta"
import Header from "./header"
import Footer from './footer'
import Cookies from './cookies'

export const TopCTAStateContext = React.createContext(null);

const Layout = ({ children }) => {

  const [isCTAVisible, setCTAVisible] = useState(true);
  const [paddingTop, setPaddingTop] = useState(0);

  useEffect(() => {

    // Make room for top CTA banner
    setPaddingTop(document.getElementById('main-menu').offsetHeight);

    window.onresize = () => {
      setPaddingTop(document.getElementById('main-menu').offsetHeight);
    }

    // Check if CTA hidden in localstorage
    const hiddenCta = localStorage.getItem('hiddenCTA');

    if(hiddenCta) {
      setCTAVisible(false);
    }

  });

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const closeCTA = () => {
    setCTAVisible(false);
    localStorage.setItem('hiddenCTA', true);
  };

  return (
    <>
      <div id="main-menu" className="main-menu">
        <CTA
          isCTAVisible={isCTAVisible}
          handleCloseCTA={() => closeCTA()}
        />
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      </div>
      <main id="main-content" style={{ paddingTop: paddingTop }}>{children}</main>
      <Footer />
      <Cookies />
    </>
  )
}

export default Layout;
